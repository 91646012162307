import { useEffect, useState, PropsWithChildren, useRef, MouseEvent as ReactMouseEvent, useMemo, useCallback } from 'react';
import { CircularProgress, DialogContent, DialogActions, Button, Typography, IconButton, useMediaQuery, Theme, Box } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { BroadcastChannel } from 'broadcast-channel';
import { useDisplayEventTracking } from '@/hooks/useDisplayEventTracking';
import { useClickEventTracking } from '@/hooks/useClickEventTracking';
import { componentNameToMyPhxKebabFormat } from '@/helpers/string-utils';
import config from '@/helpers/config';
import Icon from '@/components/atoms/icon';
import LinkButton from '@/components/atoms/link-button';
import { AppIcon } from '@/constants/icons';
import { convertHtmlToBase64, downloadImage } from '@/helpers/image-processing';
import { LINKEDIN_CHANNEL } from '@/constants/social-modal';
import { Dialog, DialogTitle, ActionsBox, AccessibleIconButton, SuccessBox, ErrorBox } from './SocialModal.styles';

type SocialModalType = PropsWithChildren<{
  ctaText: string;
  ctaIcon?: AppIcon;
  downloadFileName?: string;
  linkedInDisabled?: boolean;
}>;

function stateGenerator() {
  return uuidv4().replace(/-/g, '').substring(0, 7);
}

function redirectURLGenerator(redirectURI: string, state: string) {
  const encodedURL = encodeURIComponent(redirectURI).replace(/'/g, '%27').replace(/"/g, '%22');
  return `https://www.linkedin.com/oauth/v2/authorization?response_type=code&state=${state}&scope=r_liteprofile w_member_social&client_id=77rselzxof6nik&redirect_uri=${encodedURL}`;
}

function SharingLoader() {
  return (
    <>
      <CircularProgress size={30} sx={{ mr: '1rem' }} />
      <Typography variant="body4" sx={{ mr: '1.5rem' }}>In progress...</Typography>
    </>
  );
}

function SocialModal(
  { children, ctaText, ctaIcon, downloadFileName, linkedInDisabled = false }: SocialModalType,
) {
  const trackDisplay = useDisplayEventTracking();
  const trackClick = useClickEventTracking();
  const [loginPopUp, setLoginPopUp] = useState(null);
  const [open, setOpen] = useState(false);
  const [sharing, setSharingFlag] = useState(false);
  const [showSuccessMessage, setSuccessMessageFlag] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorDismissed, setErrorDismissedFlag] = useState(false);
  const [alreadyShared, setAlreadySharedFlag] = useState(false);
  const printableRef = useRef(null);
  const { aem } = config;
  const componentName = 'SocialModal';

  const linkedInPopUpUri = `${window?.origin}/login/linkedin`;

  const [currentLinkedInState] = useState(stateGenerator());

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const channel = useMemo(() => (currentLinkedInState ? new BroadcastChannel(`${LINKEDIN_CHANNEL}-${currentLinkedInState}`) : null), [currentLinkedInState]);

  const sharePost = useCallback(async (code: string) => {
    setSharingFlag(true);
    const imageData = await convertHtmlToBase64(printableRef?.current);
    if (imageData && !alreadyShared) {
      const url = `${aem.baseUrl}/api/services/share-post?name=linkedin`;
      const request = {
        method: 'POST',
        body: JSON.stringify({
          code,
          imageData,
          redirectUri: linkedInPopUpUri,
          imageAltText: 'University of Phoenix program progress',
          shareText: '',
        }),
      };
      const response = await fetch(url, request);
      if (response.ok) {
        setAlreadySharedFlag(true);
        setSuccessMessageFlag(true);
      } else {
        setSuccessMessageFlag(false);
        setErrorDismissedFlag(false);
        setErrorMessage('Something went wrong sharing your post. Please, try again later.');
      }
    }
    setSharingFlag(false);
  }, [aem.baseUrl, alreadyShared, linkedInPopUpUri]);

  useEffect(() => {
    if (channel && loginPopUp) {
      channel.onmessage = (data) => {
        if (loginPopUp) loginPopUp?.close();
        if (!data.error) {
          sharePost(data?.code);
        } else {
          setErrorMessage(data?.errorMessage || 'Something went wrong logging you in. Please, try again later.');
        }
      };

      return () => { channel?.close(); };
    }

    return () => { };
  }, [channel, loginPopUp, sharePost]);

  const handleClick = (action: string) => {
    trackClick(
      componentName,
      [{ text: action }, { ctaText }],
    );
  };

  const handleDisplay = useCallback(async (subComponent: string) => {
    trackDisplay(
      componentName,
      [{ text: subComponent }, { ctaText }],
    );
  }, [trackDisplay, ctaText]);

  useEffect(() => {
    if (showSuccessMessage) {
      handleDisplay('success');
    }
  }, [showSuccessMessage, handleDisplay]);

  useEffect(() => {
    if (errorMessage) {
      handleDisplay('error');
    }
  }, [errorMessage, handleDisplay]);

  const handleOpen = () => {
    handleClick('open');
    setOpen(true);
  };

  const handleClose = (action: string) => {
    handleClick(action);
    setOpen(false);
    setSuccessMessageFlag(false);
    setErrorMessage('');
    setErrorDismissedFlag(false);
    setSharingFlag(false);
  };

  const handleDownload = () => {
    handleClick('download');
    downloadImage(printableRef?.current, downloadFileName);
  };

  const redirectHandler = async (e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    handleClick('share');
    if (!alreadyShared) {
      const redirectUri = redirectURLGenerator(
        linkedInPopUpUri,
        currentLinkedInState,
      );
      const newPopUp = window?.open(redirectUri);
      setLoginPopUp(newPopUp);
    }
  };

  const handleSuccessClose = () => {
    handleClick('close-success');
    setSuccessMessageFlag(false);
  };

  const handleErrorClose = () => {
    handleClick('close-error');
    setErrorDismissedFlag(true); setErrorMessage('');
  };

  return (
    <>
      <Box display="flex" justifyContent="flex-start" alignItems="center" gap={1}>
        {ctaIcon && (<Icon id="social-modal-open-cta" icon={ctaIcon} />)}
        <LinkButton version="red-hover" onClick={handleOpen}>{ctaText}</LinkButton>
      </Box>
      <Dialog
        data-component={componentNameToMyPhxKebabFormat(componentName)}
        open={open}
        onClose={() => handleClose('blur')}
        fullScreen={isMobile}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{ borderStyle: 'solid', borderWidth: 1, borderColor: 'almostBlack.l70' }}>
          <Typography variant="body4" sx={{ fontWeight: 400 }}>{(sharing || alreadyShared) ? 'Share with Linkedin' : 'Preview'}</Typography>
          <LinkButton
            onClick={() => handleClose('close')}
          >
            Close
          </LinkButton>
        </DialogTitle>
        <DialogContent sx={{ padding: 0 }}>
          <Box sx={{ backgroundColor: 'almostBlack.l40' }} ref={printableRef}>
            {children}
          </Box>
          <Box>
            {(showSuccessMessage || (errorMessage && !errorDismissed)) && (
              <>
                { showSuccessMessage && (
                  <SuccessBox>
                    <Typography variant="body4">Success! Your progress has been shared.</Typography>
                    <IconButton
                      onClick={handleSuccessClose}
                      aria-label="Close success message"
                    >
                      <Icon
                        id="icon-close"
                        icon="icon-close"
                      />
                    </IconButton>
                  </SuccessBox>
                )}
                { errorMessage && (
                  <ErrorBox>
                    <Typography variant="body4">{errorMessage}</Typography>
                    <IconButton
                      onClick={handleErrorClose}
                      aria-label="Close error message"
                    >
                      <Icon
                        id="icon-close"
                        icon="icon-close"
                      />
                    </IconButton>
                  </ErrorBox>
                )}
              </>
            )}
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: 0, borderStyle: 'solid', borderWidth: 1, borderColor: 'almostBlack.l70' }}>
          <ActionsBox>
            {linkedInDisabled ? (<Button variant="contained" onClick={() => handleClose('download')}>Download</Button>) : (
              <>
                {sharing ? (<SharingLoader />) : (
                  <>
                    <Typography variant="body4">Share:</Typography>
                    <AccessibleIconButton
                      disableFocusRipple
                      disableRipple
                      disableTouchRipple
                      onClick={redirectHandler}
                      disabled={alreadyShared}
                      aria-label="Post to Linked In"
                    >
                      <Icon id="linkedin" icon="icon-linkedin" />
                    </AccessibleIconButton>
                  </>
                )}
                <Typography variant="body4" sx={{ borderLeft: 1, borderLeftColor: 'almostBlack.l50', pl: 1 }}>Download:</Typography>
                <AccessibleIconButton
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                  onClick={handleDownload}
                  aria-label="Download image file"
                >
                  <Icon id="download" icon="icon-download" />
                </AccessibleIconButton>
              </>
            )}
          </ActionsBox>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SocialModal;
